import React from "react"

import Layout from "../components/Layout"
import Grid from "../components/Grid"
import css from "./privacy.module.scss"
import fontType from "../helpers/fontType.module.scss"

const metadata = {
  title: "Anafore Privacy Policy | ReferralCandy",
  noindex: true,
}

const PrivacyPage = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Privacy Policy</h1>
        <p>
          We are Anafore Pte. Ltd., the company that runs the ReferralCandy
          service. We’re also known as ReferralCandy. Here’s a summary of how we
          protect your data and respect your privacy.
        </p>

        <h2>Who this policy is for</h2>
        <p>
          This policy applies to any user of our Site (located at
          referralcandy.com, refr.cc) or Services, including retailers, agents,
          and others who have an account with ReferralCandy ("Retailers") and
          customers of Retailers or people referred to Retailers ("Customers").
        </p>
        <p>
          If you are a Customer of a Retailer who is running a referral program
          using ReferralCandy, you should also check the privacy policy of the
          Retailer that the referral program is for.
        </p>

        <h2>When we collect data</h2>

        <p className={`${fontType.b1} ${css.subheader}`}>
          When you visit our website and use our service
        </p>
        <p>
          Whenever you interact with ReferralCandy, we collect your data.
          Sometimes we request you provide us with data, sometimes data about
          you is collected automatically (we call this "log data").
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          When you voluntarily submit information to us
        </p>
        <p>
          We collect data like your email address and name that you voluntarily
          submit to us (such as registering to use the ReferralCandy service or
          signing up for a webinar).
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Information About Children
        </p>
        <p>
          ReferralCandy is not intended for or targeted at persons under 16. By
          using this site, you represent that you are not under the age of 16.
          We do not knowingly collect information about children under 16. If
          you believe that we have collected information about a child under 16,
          please contact us at compliance@referralcandy.com, and we will delete
          such information.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Information we collect from third parties
        </p>
        <p>
          We may receive personal data about you from Retailers providing it
          directly to us (such as after you have purchased from the Retailer),
          third parties including partners with which we offer joint marketing
          activities with or have integrations with, and from publicly available
          sources such as social media websites that you choose to connect with
          ReferralCandy
        </p>

        <h2>Type of data we collect</h2>

        <p className={`${fontType.b1} ${css.subheader}`}>Contact details</p>
        <p>
          Your name, email address, social media accounts (when you choose to
          connect with ReferralCandy), address, telephone number.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Log Data that identifies you
        </p>
        <p>
          Log Data may include information such as your IP address, browser
          details, timezone, geolocation information, login details, operating
          system and version.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Data from Retailer's store
        </p>
        <p>
          Details about and from the Retailer's store, orders Customers make and
          tracking data for those orders.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Data on how you interact with ReferralCandy
        </p>
        <p>
          Usage information about how you interact with ReferralCandy which may
          include information on clicks, pages visited, actions taken, errors
          encountered.
        </p>

        <h2>How we use the data we collect</h2>

        <p className={`${fontType.b1} ${css.subheader}`}>
          We do not sell personal information for commercial purposes
        </p>
        <p>
          We do not sell your personal data to other organizations for
          commercial purposes. We only use your data for your referral program.
          If our service is acquired or merged with another company, your
          information may be transferred to the new owners so that we may
          continue to provide our services to you.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          We only use your data for reasons that we have a legal basis to do so.{" "}
        </p>
        <p>Here’s how we use it:</p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          To run your referral program
        </p>
        <p className={css.hasprint}>
          Managing different parts of your referral program like logging you
          into your ReferralCandy dashboard, storing settings and preferences,
          processing payments, tracking referrals and how Customers use your
          referral program, inviting Customers to take part, sending you updates
          about the referral program, hosting and infrastructue, investigating
          fraud, spam, and other illegal activities
        </p>
        <p className={fontType.b4}>
          Legal basis: Legitimate interests, contract
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          To improve ReferralCandy
        </p>
        <p className={css.hasprint}>
          Tracking the experience of using ReferralCandy, testing features,
          getting feedback, managing landing pages, research and analyzing data
          including profiling and in some instances using third parties to do
          this.
        </p>
        <p className={fontType.b4}>
          Legal basis: Legitimate interests, contract
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>Support</p>
        <p className={css.hasprint}>
          Resolving issues via live chat, phone or email support, letting you
          know about changes to our service and how the referral program is
          doing
        </p>
        <p className={fontType.b4}>Legal basis: Contract</p>

        <p className={`${fontType.b1} ${css.subheader}`}>Marketing</p>
        <p className={css.hasprint}>
          Sending emails and messages to you about updates to your referral
          program, changes to the service, new features, products and services
          and content
        </p>
        <p className={fontType.b4}>Legal basis: Legitimate Interest, Consent</p>

        <h2>Your privacy choices</h2>

        <p className={`${fontType.b1} ${css.subheader}`}>
          You can choose not to provide us with personal data
        </p>
        <p>
          In instances where you are presented the option of providing us with
          personal data, you can decline to do so. If you decline to do so, you
          can still browse our website page, but we will not be able to run a
          referral program for you.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          You are able to turn off cookies in your browser
        </p>
        <p>
          You can change the settings in your browser to turn off cookies. You
          can also delete cookies in your browser settings. Note that certain
          features of our service may not function properly without the aid of
          cookies.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>Opt out of Emails</p>
        <p>
          Merchants and Customers can always opt out of receiving emails from
          ReferralCandy by following unsubscribe instructions in emails sent by
          ReferralCandy or by emailing us at support@referralcandy.com.
        </p>

        <h2>Your rights</h2>

        <p>You have the following data protection rights:</p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to access information that we have on you
        </p>
        <p>
          If you request it, we will provide you this information within one
          month of the request, unless doing so would adversely affect the
          rights and freedoms of others. We will let you know if we are unable
          to do so because of that reason.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to correct inaccurate personal data
        </p>
        <p>
          You are able to change personal data (like name, contact information)
          in your ReferralCandy dashboard. If you want us to correct any other
          personal data that you aren't able to correct yourself, you can email
          us at support@referralcandy.com with a request to do so.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to object the use of your data for profiling or making automated
          decisions about you
        </p>
        <p>
          We use your data to figure out what information is relevant to you to
          give you a better experience (like using your usage information to
          determine content of emails sent to you or determining what to show
          you on your dashboard). We will only do this to provide and improve
          the ReferralCandy service.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to port your data to another service
        </p>
        <p>
          If you request it, we will hand you a copy of your data in CSV format
          so you can provide it to another service. We will not do so if the
          request adversely affects any rights and freedoms of others.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to be forgotten by us
        </p>
        <p>
          You can ask us to delete any personal data that we have about you,
          provided that it is no longer needed for us to use that data for
          purposes of your use of ReferralCandy.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Right to lodge a complaint regarding our use of your data
        </p>
        <p>
          You can address any complaints to the supervisory authority in the
          Member State of your residence or place of work. Before you do so,
          please bring it up with us so we can address your concerns.
        </p>

        <p>
          To exercise any of these rights, please contact us as
          compliance@referralcandy.com.
        </p>

        <h2>How we protect your data</h2>
        <p>
          We take data protection seriously. We have a variety of administrative
          and electronic measures that are designed to protect your personal
          data against unauthorized access, use or disclosure.
        </p>

        <p className={`${fontType.b1} ${css.subheader}`}>
          Third parties who process your data
        </p>
        <p>
          We use third parties to help us to offer and run the referral program
          for you. When we do this, sometimes we need to share your data with
          them for their services to work well. The sharing only happens when it
          is strictly necessary and done so with the safeguards and practices
          detailed in this privacy policy.
        </p>

        <p>
          There are also instances where you voluntarily connect third party
          services to your ReferralCandy account. When you do so, we may need to
          share your data with them in order to provide you a seamless
          experience running your referral program.
        </p>

        <p>
          Customer Information provided to ReferralCandy is also subject to the
          Retailer's Privacy Policy.
        </p>

        <p>
          We recommend that you read the privacy policies of the Retailer and
          third party service providers so you can understand the manner in
          which your personal information will be handled by them.
        </p>

        <p>Here are the details of our main third-party service providers:</p>
        <ul>
          <li>
            <p>
              <span className={css.bold}>Analytics:</span> Google Analytics,
              Fullstory, Optimizely, Segment, LinkedIn, Facebook,
              FitSmallBusiness, AdRoll, Twitter, RetargetLinks, Unbounce,
              Microsoft, Quora
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Feedback and support:</span>{" "}
              Intercom.io, Desk.com, Delighted.com, FreshDesk
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Communication:</span> Mailchimp,
              Customer.io, Vero, Sumo, Slack
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Hosting and infrastructure:</span>{" "}
              Amazon Web Services, Sendgrid, VictorOps, Rollbar, Errorception
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Payment:</span> Stripe, PayPal
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Integrations:</span> Shopify,
              BigCommerce, Mailchimp, ReCharge, Woocommerce, Fomo
            </p>
          </li>
          <li>
            <p>
              <span className={css.bold}>Audit and Finance:</span> Xero,
              PromptAudit PAC
            </p>
          </li>
        </ul>

        <h2>How long do we store your data</h2>
        <p>
          We keep your data as long as you're using ReferralCandy, and in
          backups after you've stopped using ReferralCandy for up to 6 months.
        </p>

        <h2>Where do we store your data</h2>
        <p>
          Your data is stored through Amazon's databases on a secure server
          behind a firewall. We might transfer and store the data we collect
          from you somewhere outside the European Economic Area (‘EEA’). People
          who work for us or our suppliers outside the EEA might also process
          your data.
        </p>

        <h2>How we respond to Do Not Track (DNT)</h2>
        <p>
          Because there is no legal or industry standard at this time, we do not
          respond to "DNT" signals. We are closely monitoring the work of the
          privacy community to determine when such a response is appropriate and
          what form it should take.
        </p>

        <h2>Cookies</h2>
        <p>
          We use cookies when you interact with ReferralCandy. You can always
          choose to block cookies using your browser settings. ReferralCandy and
          third parties that we use set cookies whenever you interact with
          ReferralCandy. These cookies may be session cookies which are deleted
          when you leave ReferralCandy, or persistent cookies which do not
          delete themselves and enable us to recognize you when you return so we
          can provide a better experience for you.
        </p>
        <p>Here’s our full cookie policy:</p>
        <ul>
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/11118835/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Retailer Cookie Policy
            </a>
          </li>
        </ul>
        <p>
          On the friend landing page and widget in particular, the cookie policy
          is determined by the Retailer and is not covered by the cookie policy
          linked above.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website. If we make
          material changes to this policy, we will notify you here that it has
          been updated, so that you are aware of what information we collect,
          how we use it, and under what circumstances, if any, we use and/or
          disclose it.
        </p>

        <h2>How to contact us</h2>
        <p>
          If you have any questions regarding this Privacy Policy, please
          contact us by emailing support@referralcandy.com.
        </p>
        <br />
        <p className={fontType.b4}>Effective as of December 6, 2019</p>
      </Grid>
    </Layout>
  )
}

export default PrivacyPage
